
h2, .h2 {
  color: #49F103
}

.pagination > .active > a, .pagination > .active > a:hover, .pagination > .active > a:focus, .pagination > .active > span, .pagination > .active > span:hover, .pagination > .active > span:focus {
  background-color: #49F103;
  border-color: #49F103;
}

.pagination > li > a, .pagination > li > span {
  color: #49F103;
}

.content_p a {
  color: #49F103;
}

.pagination.pull-right li:not(.active) a:hover {
  color: #49f103 !important;
}

a {
  color: #fff;
}

a:hover, a:focus {
  color: #fff;
}

body {
  color: #fff;
  background: #000;
}

#global {
  background: #000;
}

.slogan {
  color: #49F103;
}

nav#nav-principal a {
  color: #898989;
}

nav#nav-principal li:hover > a, nav#nav-principal li.actif > a{
  color: #49F103;
}

#contenu {
  color: #FFF;
}

#contenu h3,
#contenu .h3, #fiche-produit .wrap-description h1, #fiche-produit .wrap-description .h1 {
  color: #49F103;
  border-bottom: 1px solid;
}

#contenu a:hover, #contenu a:focus {
  color: #eee;
}

#menu_gauche h2,
#menu_gauche .h2 {
  color: #49F103;
}

#menu_gauche li a {
  color: #707070;
}

#menu_gauche li a:hover, #menu_gauche li a:focus {
  color: #49F103;
}

#footer {
  background: url(../images/imgd218series/bg_footer.png) no-repeat scroll center top transparent;
  color: #fff;
}

.produits {
  border: 1px solid #444;
}

.remise {
  background: url(../images/vignette_promo.png) no-repeat top left;
  color: #FFF;
}

.produits p.nomprod {
  color: #FFF;
}



ul#menugalerie {
  border-top: 1px solid #ccc;
}

ul#menugalerie h3,
ul#menugalerie .h3 {
  border-bottom: 2px dotted #bbb;
}

ul#menugalerie li {
  background-color: #333;
}

ul#menugalerie li a {
  color: #FFF;
}

ul#menugalerie li:hover {
  background-color: #FFF;
}

.galerie {
  border: 1px solid #444;
}

.galerie img {
  border: 1px solid #444;
}

.diaporama {
  color: #FFF;
  border-top: 1px solid #ccc;
}

.diaporama a {
  color: #FFF;
}

.diaporama a:hover {
  color: #FFF;
}

.message_lo {
  border: 1px solid #FFF;
}

.message_lo p.web a {
  color: #222;
}

a.addmsglo {
  color: #FFF;
  background: #2a2a2a;
  border: 1px solid #ccc;
}

a.addmsglo:hover {
  background: #444;
  border: 1px solid #49F103;
}

#addmsg input {
  border: 1px solid #ccc;
}

#addmsg textarea {
  border: 1px solid #ccc;
}

#addmsg input[type=submit] {
  background: #2a2a2a;
  border: 1px solid #ccc;
  color: #FFF;
}

#addmsg input[type=submit]:hover {
  background: #2a2a2a;
  border: 1px solid #49F103;
}

#addmsg a:hover {
  color: #AF9C78;
}

#form1 input, #form1 textarea {
  border: 1px solid #bbb;
  background: #fff url(../../../images/form1/form_input.gif) repeat-x;
}

#form1 input.button, .button {
  color: #FFF;
  background: #2a2a2a;
  border: 1px solid #ccc;
}

#form1 input.button:hover, #paiementchoix .button:hover, #totalbloc .button:hover {
  background: #444;
  border: 1px solid #49F103;
}

#panier {
  color: #000 !important;
}

.produits:hover, .galerie:hover, .galerie img:hover, #form1 input:hover, #form1 textarea:hover {
  border: 1px solid #49F103;
}

ul#menugalerie h3:first-letter, ul#menugalerie .h3:first-letter, .galerie a, .galerie a:hover, #form1 p:first-letter {
  color: #FFF;
}

.produit_etiquette:after {
  border-color: rgb(111, 201, 72) rgba(65, 129, 217, 0) rgba(65, 129, 217, 0);
}

.produit_etiquette {
  background: rgb(111, 201, 72);
}

.produit_etiquette2 {
  background: rgb(111, 201, 72);
}

.produit_etiquette2:after {
  border-color: rgba(0, 0, 0, 0) rgb(75, 165, 33) rgba(0, 0, 0, 0) rgba(0, 0, 0, 0);
}

#menu_gauche {
  background: linear-gradient(to bottom, #5d5d5d 0%, #000000 4%, #434343 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#5d5d5d', endColorstr='#434343', GradientType=0); /* IE6-9 */
  border-left: 2px solid #565656;
  border-top: 1px solid #565656;
}

.produits a.addbasket, #fiche-produit a.addbasket.button {
  color: #fff;
  background-color: #6FC948;
  border: 1px solid #fff;
}

#entete.header_p {
  background: linear-gradient(to bottom, #5d5d5d 0%, #000000 4%, #434343 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#5d5d5d', endColorstr='#434343', GradientType=0); /* IE6-9 */
}

nav#nav-principal {
  border-top: 12px solid #000;
  background: linear-gradient(to bottom, #5d5d5d 0%, #000000 25%, #434343 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#5d5d5d', endColorstr='#434343', GradientType=0); /* IE6-9 */
  ul ul{
    background: #5d5d5d;
  }
}

@media screen and (max-width:768px){
  nav#nav-principal > ul li{
    background-color:#000;
    li{
      background-color:#434343;
    }
  }
}

/** Flux Panier **/

.button.button-secondary {
  background: orange;

  &:hover {
    background: #444;
  }
}

#flux-panier.template_flux_panier.template_flux_panier_1 {
  color: #000;
}

#flux-panier.template_flux_panier.template_flux_panier_1 .side-tabs ul li.step-done {
  background: #999;
  border-color: #999;

  a {
    color: #fff;
  }
}

#flux-panier.template_flux_panier.template_flux_panier_1 .side-tabs ul li.active {
  border-color: #999;
  color: #999;

  a, span {
    color: #999;
  }
}